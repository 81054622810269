import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BasicInformation } from 'components/admin-onboard/BasicInformation';
import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { adminOnboardSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

export function AdminOnboard() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const adminOnboardMutation = useMutationWithToast(
    UserService.onBoardAdmin,
    {}
  );
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(adminOnboardSchema),
    defaultValues: {
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    localStorage.removeItem('login_redirect');
    adminOnboardMutation.mutate(
      {
        token: token ?? '',
        adminDetails: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          phone: `+${data.phone}`,
        },
      },
      {
        onSuccess: () => {
          navigate({ pathname: '/login' });
        },
      }
    );
  });

  return (
    <div className="min-h-screen w-full bg-background-dark p-24">
      <div className=" ">
        <Typography variant="h1">
          <span className="flex">
            Register as{' '}
            <Typography
              className="mx-2 inline-block"
              variant="h1"
              color="primary">
              Brite
            </Typography>{' '}
            admin
          </span>
        </Typography>
        <Typography variant="h3" className="mt-2">
          Please provide us with the following information to register yourself.
        </Typography>
      </div>
      <div className="mt-12 grid gap-4">
        <BasicInformation
          watch={watch}
          control={control}
          errors={errors}
          onboardMutation={adminOnboardMutation}
        />
        <div className="flex justify-end">
          <Button loading={adminOnboardMutation.isLoading} onClick={onSubmit}>
            Register <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
}
