import React from 'react';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

type PasswordCheckerProps = {
  validations: {
    length: boolean;
    lowercase: boolean;
    uppercase: boolean;
    number: boolean;
    specialChar: boolean;
  };
  className?: string;
};

export function PasswordChecker({
  validations,
  className,
}: PasswordCheckerProps) {
  return (
    <ul
      className={classNames(
        'pointer-events-none flex list-none flex-col gap-1 rounded border border-[#414141] bg-background-light p-4',
        className
      )}>
      <Typography variant="h5" className="mb-2">
        Password must contains:{' '}
      </Typography>
      <li className="flex gap-1">
        <CheckCircleIcon
          className={classNames('h-6 w-6 text-[#616161]', {
            '!text-primary-main': validations.length,
          })}
        />
        <Typography color="textMuted" variant="subtitle2">
          At least 8 characters long
        </Typography>
      </li>
      <li className="flex gap-1">
        <CheckCircleIcon
          className={classNames('h-6 w-6 text-[#616161]', {
            '!text-primary-main': validations.lowercase,
          })}
        />
        <Typography color="textMuted" variant="subtitle2">
          At least one lowercase letter
        </Typography>
      </li>
      <li className="flex gap-1">
        <CheckCircleIcon
          className={classNames('h-6 w-6 text-[#616161]', {
            '!text-primary-main': validations.uppercase,
          })}
        />
        <Typography color="textMuted" variant="subtitle2">
          At least one uppercase letter
        </Typography>
      </li>
      <li className="flex gap-1">
        <CheckCircleIcon
          className={classNames('h-6 w-6 text-[#616161]', {
            '!text-primary-main': validations.number,
          })}
        />
        <Typography color="textMuted" variant="subtitle2">
          At least one number
        </Typography>
      </li>

      <li className="flex gap-1">
        <CheckCircleIcon
          className={classNames('h-6 w-6 !min-w-[24px] text-[#616161]', {
            '!text-primary-main': validations.specialChar,
          })}
        />
        <Typography color="textMuted" variant="subtitle2">
          At least one special character (-, @, #, $, %, ^, &, *, (, ), _)
        </Typography>
      </li>
    </ul>
  );
}
