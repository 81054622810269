import React, { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { TextInput } from 'components/common/Input/TextInput';
import { providerContactDetailsSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { InputFieldType } from 'types/common';

import { useOnboardProviderFormContext } from './OnboardFormContext';

type ContactDetailsForm = {
  officeManagerEmail: string;
  officeManagerName: string;
  phone: string;
  offersTelemedicine: boolean;
  link?: string;
};

export function ContactDetails() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [isOffersTelemedicine, setOffersTelemedicine] =
    useState<boolean>(false);

  const { formState, updateFormState, formErrors } =
    useOnboardProviderFormContext();

  const { control, handleSubmit, reset, setError } =
    useFormWithErrors<ContactDetailsForm>({
      schema: providerContactDetailsSchema,
    });

  useEffect(() => {
    if (formErrors && formErrors?.length) {
      // eslint-disable-next-line array-callback-return
      formErrors.map((item, index) => {
        const keys = Object.keys(item);

        if (keys[index] === 'phone') {
          setError('phone', { message: item[keys[index]] });
        }
      });
    }
  }, [formErrors, setError]);

  useEffect(() => {
    if (formState.contactDetails) {
      const {
        officeManagerEmail,
        officeManagerName,
        phoneNumber,
        offersTelemedicine,
        link,
      } = formState.contactDetails;

      setOffersTelemedicine(offersTelemedicine);
      reset({
        officeManagerName,
        officeManagerEmail,
        phone: phoneNumber,
        link,
      });
    }
  }, [formState, reset]);

  const onSubmit = handleSubmit((data) => {
    const { officeManagerEmail, officeManagerName, phone } = data;

    updateFormState('contactDetails', {
      officeManagerEmail,
      officeManagerName,
      phoneNumber: phone,
      offersTelemedicine: isOffersTelemedicine,
      link: data.link,
    });

    navigate({
      pathname: '../create-password',
      search: `token=${token}`,
    });
  });

  return (
    <div className=" mt-4 flex flex-col">
      <div className="grid grid-cols-12">
        <div className="col-span-9 lg:col-span-9">
          <div className="flex gap-4">
            <TextInput
              required
              label="Office Manager Name"
              type={InputFieldType.Text}
              placeholder="Office Manager Name"
              fullWidth
              name="officeManagerName"
              control={control}
              removeWhiteSpace
              allowWhiteSpaceInBetween
            />
            <TextInput
              required
              label="Office Manager Email"
              type={InputFieldType.Text}
              placeholder="Manager Email "
              className="  "
              fullWidth
              name="officeManagerEmail"
              control={control}
              removeWhiteSpace
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-9  lg:col-span-9">
          <TextInput
            required
            label="Phone number"
            type={InputFieldType.Phone}
            placeholder="Phone Number"
            className="placeholder:text-zinc-400  "
            fullWidth
            name="phone"
            control={control}
          />
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-9  lg:col-span-9">
          <TextInput
            label="Your Telehealth/Appointment booking link"
            type={InputFieldType.Text}
            placeholder="Telehealth/Appointment booking link"
            className="placeholder:text-zinc-400  "
            fullWidth
            name="link"
            control={control}
          />
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-9 lg:col-span-9">
          <CheckBox
            checked={isOffersTelemedicine}
            onChange={(checked) => setOffersTelemedicine(checked)}
            id="offers-telemedicine"
            label="Will you be offering telemedicine for your hormone services?"
          />
        </div>
      </div>
      <div className="mt-8 flex gap-4 ">
        <Button
          variant="outlined"
          onClick={() => {
            navigate({
              pathname: '../npi-dea-license',
              search: `token=${token}`,
            });
          }}>
          Go Back
        </Button>{' '}
        <Button onClick={onSubmit}>Next</Button>
      </div>
    </div>
  );
}
