import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { PasswordChecker } from 'components/common/PasswordChecker/PasswordChecker';
import { resetPasswordSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { AuthenticationService } from 'services/authentication';
import { InputFieldType } from 'types/common';

type ResetPasswordFormType = {
  password: string;
  confirmPassword: string;
};

export function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordMutation = useMutationWithToast(
    AuthenticationService.resetPassword,
    {}
  );
  const token = searchParams.get('token');

  const { handleSubmit, control, watch } =
    useFormWithErrors<ResetPasswordFormType>({
      mutation: resetPasswordMutation,
      schema: resetPasswordSchema,
    });

  const onSubmit = handleSubmit((data) => {
    resetPasswordMutation.mutate(
      { password: data.password, token: token ?? '' },
      {
        onSuccess: () => {
          navigate({ pathname: '/login' });
        },
      }
    );
  });

  const password = watch('password', '');
  const validations = {
    length: password?.length >= 8,
    lowercase: /[a-z]/.test(password ?? ''),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password),
    specialChar: /[-@#$%^&*()_]/.test(password),
  };

  return (
    <form
      onSubmit={onSubmit}
      className=" grid w-full place-items-center rounded-sm px-8 py-10 pb-8">
      <h1 className="mb-8 text-white">Password Reset</h1>

      <TextInput
        className="pl-2"
        type={InputFieldType.Password}
        name="password"
        control={control}
        placeholder="New Password"
        fullWidth
        removeWhiteSpace
      />
      <TextInput
        className="pl-2"
        type={InputFieldType.Password}
        name="confirmPassword"
        control={control}
        placeholder="Confirm Password"
        fullWidth
        removeWhiteSpace
      />
      <PasswordChecker validations={validations} />

      <div className="row mt-6 flex w-full items-center justify-end">
        <Button type="submit" loading={resetPasswordMutation.isLoading}>
          Reset
          <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
        </Button>
      </div>
    </form>
  );
}
