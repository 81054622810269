import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { emailSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { AuthenticationService } from 'services/authentication';
import { InputFieldType } from 'types/common';

type ForgotPasswordFormType = {
  email: string;
};

export function ForgotPassword() {
  const navigate = useNavigate();
  const sendVerificationMailMutation = useMutationWithToast(
    AuthenticationService.sendVerificationMail,
    {}
  );
  const { handleSubmit, control } = useFormWithErrors<ForgotPasswordFormType>({
    mutation: sendVerificationMailMutation,
    schema: emailSchema,
  });
  const onSubmit = handleSubmit((data) => {
    sendVerificationMailMutation.mutate(
      { email: data.email },
      {
        onSuccess: () => {
          navigate({ pathname: '../login' });
        },
      }
    );
  });

  return (
    <form
      onSubmit={onSubmit}
      className="grid place-items-center rounded-sm px-8 py-10 pb-8">
      <h1 className="mb-8 text-white">Password Reset</h1>

      <TextInput
        type={InputFieldType.Text}
        name="email"
        control={control}
        placeholder="Enter email address"
        fullWidth
        removeWhiteSpace
      />
      <div className="row mt-2 flex w-full items-center justify-end">
        <Button type="submit" loading={sendVerificationMailMutation.isLoading}>
          Reset Password
        </Button>
      </div>
    </form>
  );
}
